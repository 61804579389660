import ar from './locale/ar.json';
import ca from './locale/ca.json';
import cs from './locale/cs.json';
import da from './locale/da.json';
import de from './locale/de.json';
import el from './locale/el.json';
import en from './locale/en.json';
import es from './locale/es.json';
import fa from './locale/fa.json';
import fi from './locale/fi.json';
import fr from './locale/fr.json';
import hi from './locale/hi.json';
import hu from './locale/hu.json';
import id from './locale/id.json';
import it from './locale/it.json';
import ja from './locale/ja.json';
import ko from './locale/ko.json';
import ml from './locale/ml.json';
import nl from './locale/nl.json';
import no from './locale/no.json';
import pl from './locale/pl.json';
import pt from './locale/pt.json';
import pt_BR from './locale/pt_BR.json';
import ro from './locale/ro.json';
import ru from './locale/ru.json';
import sk from './locale/sk.json';
import sv from './locale/sv.json';
import ta from './locale/ta.json';
import th from './locale/th.json';
import tr from './locale/tr.json';
import uk from './locale/uk.json';
import vi from './locale/vi.json';
import zh_CN from './locale/zh_CN.json';
import zh_TW from './locale/zh_TW.json';

export default {
  ja
};
