<template>
  <div class="primary--sidebar">
    <logo
      :source="logoSource"
      :name="installationName"
      :account-id="accountId"
    />
    <nav class="menu vertical">
      <primary-nav-item
        v-for="menuItem in menuItems"
        :key="menuItem.toState"
        :icon="menuItem.icon"
        :name="menuItem.label"
        :to="menuItem.toState"
        :is-child-menu-active="menuItem.key === activeMenuItem"
      />
      <a style="color:var(--s-600)" referrerpolicy="no-referrer-when-downgrade" data-v-242498a4="" data-v-a6c6cd90="" href="https://meetpkg.demo.topaz.jp/console/meeting" class="button clear button--only-icon menu-item has-tooltip" data-original-title="null">
        <svg data-v-242498a4="" width="20" height="20" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M 19.183594 3.789062 L 14.738281 6.65625 L 14.738281 5.789062 C 14.738281 4.632812 13.789062 3.683594 12.632812 3.683594 L 2.105469 3.683594 C 0.949219 3.683594 0 4.632812 0 5.789062 L 0 14.210938 C 0 15.367188 0.949219 16.316406 2.105469 16.316406 L 12.632812 16.316406 C 13.789062 16.316406 14.738281 15.367188 14.738281 14.210938 L 14.738281 13.34375 L 19.183594 16.210938 C 19.683594 16.472656 20 16.027344 20 15.761719 L 20 4.210938 C 20 3.949219 19.710938 3.472656 19.183594 3.789062 Z M 13.683594 14.210938 C 13.683594 14.789062 13.210938 15.261719 12.632812 15.261719 L 2.105469 15.261719 C 1.527344 15.261719 1.050781 14.789062 1.050781 14.210938 L 1.050781 5.789062 C 1.050781 5.210938 1.527344 4.738281 2.105469 4.738281 L 12.632812 4.738281 C 13.210938 4.738281 13.683594 5.210938 13.683594 5.789062 Z M 18.949219 14.816406 L 14.738281 12.105469 L 14.738281 7.894531 L 18.949219 5.183594 Z M 18.949219 14.816406" fill="currentColor"></path></svg>
        <span data-v-242498a4="" class="show-for-sr">MEETING</span>
      </a>
    </nav>
    <div class="menu vertical user-menu">
      <notification-bell @open-notification-panel="openNotificationPanel" />
      <agent-details @toggle-menu="toggleOptions" />
      <options-menu
        :show="showOptionsMenu"
        @toggle-accounts="toggleAccountModal"
        @show-support-chat-window="toggleSupportChatWindow"
        @key-shortcut-modal="$emit('key-shortcut-modal')"
        @close="toggleOptions"
      />
    </div>
  </div>
</template>
<script>
import Logo from './Logo';
import PrimaryNavItem from './PrimaryNavItem';
import OptionsMenu from './OptionsMenu';
import AgentDetails from './AgentDetails';
import NotificationBell from './NotificationBell';
import wootConstants from 'dashboard/constants';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  components: {
    Logo,
    PrimaryNavItem,
    OptionsMenu,
    AgentDetails,
    NotificationBell,
  },
  props: {
    isACustomBrandedInstance: {
      type: Boolean,
      default: false,
    },
    logoSource: {
      type: String,
      default: '',
    },
    installationName: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    activeMenuItem: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      helpDocsURL: wootConstants.DOCS_URL,
      showOptionsMenu: false,
    };
  },
  methods: {
    frontendURL,
    toggleOptions() {
      this.showOptionsMenu = !this.showOptionsMenu;
    },
    toggleAccountModal() {
      this.$emit('toggle-accounts');
    },
    toggleSupportChatWindow() {
      window.$chatwoot.toggle();
    },
    openNotificationPanel() {
      this.$emit('open-notification-panel');
    },
  },
};
</script>
<style lang="scss" scoped>
.primary--sidebar {
  display: flex;
  flex-direction: column;
  width: var(--space-jumbo);
  border-right: 1px solid var(--s-50);
  box-sizing: content-box;
  height: 100%;
  flex-shrink: 0;
}

.menu {
  align-items: center;
  margin-top: var(--space-medium);
}

.user-menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: var(--space-normal);
}
</style>
